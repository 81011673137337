<template>
	<div class="row-space-tbf" v-if="loaded">
		<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$router.push({name: 'vacant-jobs-show', params: { slug: vacantJobAnnouncement.vacant_job.slug} })"><icon-arrow-back /></div></div>
		<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{ $t('job_announcement.job_announcement_title_form', { name: vacantJobAnnouncement.vacant_job.name})}}</h1>
				<div class="action">
					<button class="btn-tbf white only-icon" @click="$router.push({name: 'vacant-jobs-show', params: { slug: vacantJobAnnouncement.vacant_job.slug} })"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('job_announcement.title')}}</div>
					<div v-if="errorsBe.title" class="error-msg">{{ errorsBe.title.join(" | ") }}</div>
				</div>
				<div class="form">
					<div class="input-group w-100">
						<div v-if="$v.vacantJobAnnouncement.title.$error" class="error-msg">{{ $t('validator.required') }}</div>
						<div class="input-box" v-bind:class="{has_error: $v.vacantJobAnnouncement.title.$error}">
							<textarea :placeholder="$t('job_announcement.title_description')" class="input-text" v-model="vacantJobAnnouncement.title"></textarea>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('job_announcement.vision_mission')}}</div>
				</div>
				<div class="form">
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{$t('job_announcement.vision')}}</label>
						</div>
						<div class="input-box">
							<textarea class="input-text" v-model="vacantJobAnnouncement.target"></textarea>
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{$t('job_announcement.mission')}}</label>
						</div>
						<div class="input-box">
							<quill-editor class="editor-tbf h-200" v-model="vacantJobAnnouncement.mission" :options="{...editorOption, placeholder: 'ceva'}" />
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('job_announcement.field_of_activity')}}</div>
				</div>
				<div class="form">
					<div class="input-group w-100">
						<div class="input-box">
							<quill-editor class="editor-tbf h-300" v-model="vacantJobAnnouncement.field_of_activity" :options="{...editorOption, placeholder: $t('company.placeholder_FA')}" />
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('job_announcement.results')}}</div>
				</div>
				<div class="form">
					<div class="input-group w-100">
						<div class="input-box">
							<quill-editor class="editor-tbf h-300" v-model="vacantJobAnnouncement.successes" :options="{...editorOption, placeholder: $t('company.placeholder_RR')}" />
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('job_announcement.responsibilities')}}</div>
				</div>
				<div class="form">
					<div class="input-group w-100" v-for="role in vacantJobAnnouncement.roles" v-if="role.responsibilities.length">
						<div class="label-header">
							<label class="label">{{ role.name }}</label>
						</div>
						<ul class="list-role-responsibilities">
							<li class="item-responsibility" v-for="responsibility in role.responsibilities">
								<div class="name">{{ responsibility }}</div>
								<!-- <div class="description" v-html="responsibility.description"></div> -->
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('job_announcement.company_values')}}</div>
				</div>
				<div class="form">
					<div class="input-group w-100">
						<div class="input-box">
							<quill-editor class="editor-tbf h-300" v-model="vacantJobAnnouncement.company_values" :options="{...editorOption, placeholder: $t('company.placeholder_CV')}" />
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('job_announcement.reward')}}</div>
				</div>
				<div class="form">
					<div class="input-group w-100">
						<div v-if="$v.vacantJobAnnouncement.reward.$error" class="error-msg">{{ $t('validator.required') }}</div>
						<div class="input-box" v-bind:class="{has_error: $v.vacantJobAnnouncement.reward.$error}">
							<quill-editor class="editor-tbf h-300" v-model="vacantJobAnnouncement.reward" :options="{...editorOption, placeholder: $t('company.placeholder_reward')}" />
						</div>
					</div>
				</div>
			</div>

			<div class="form-group last">
				<div class="data-group">
					<div class="title">{{ $t('job_announcement.steps_to_follow')}}</div>
				</div>
				<div class="form">
					<div class="input-group w-100">
						<div v-if="$v.vacantJobAnnouncement.steps_to_do.$error" class="error-msg">{{ $t('validator.required') }}</div>
						<div class="input-box" v-bind:class="{has_error: $v.vacantJobAnnouncement.steps_to_do.$error}">
							<quill-editor class="editor-tbf h-300" v-model="vacantJobAnnouncement.steps_to_do" :options="{...editorOption, placeholder: $t('company.placeholder_STF')}" />
						</div>
					</div>
				</div>
			</div>

			<div class="form-submit crud">
				<div class="error-msg" v-show="error_message != ''">{{ error_message == 'check_error' ? ($v.$invalid ? $t('btn-submit.error') : '') : error_message}}</div>
				<button class="btn-tbf grey" @click="$router.push({name: 'vacant-jobs-show', params: { slug: vacantJobAnnouncement.vacant_job.slug} })"><span class="text">{{ $t('general.cancel') }}</span></button>
				<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction">
					<div class="loader-spin" v-if="loadingSubmit"></div>
					<span class="text" v-else>{{ $t('general.update') }}</span>
				</button>
			</div>
		</div>
		<div class="space-right"></div>

	</div>
	<loader-users-create  v-else/>
</template>

<script>
	import IconArrowBack from '../../Icons/ArrowLong'
	import IconClose from '../../Icons/Close'
	import Quill from 'quill'
	import ImageUploader from "quill-image-uploader/src/quill.imageUploader.js"

	import LoaderUsersCreate from '../../PagesLoaders/UsersCreate'
	import { required } from 'vuelidate/lib/validators'

	Quill.register("modules/imageUploader", ImageUploader)
	
	export default {
		components: {
			IconArrowBack,
			IconClose,
			LoaderUsersCreate
			
		},
		data() {
			return {
				loaded: false,
				vacantJobAnnouncement: {
					title: "",
					steps_to_do: "",
					reward: ""
				},
				responsibilities: {},
				editorOption: {
					modules:{
						toolbar: [
							[{ 'header': [1, 2, 3, false] }],
							['bold', 'italic', 'underline', 'strike'],
							['blockquote', 'code-block'],
							[{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'align': [] }],
							[{ 'color': [] }, { 'background': [] }],
							['link', 'image', 'video'],
							['clean']
						],
						imageUploader: {
							upload: (file) => {
								return new Promise((resolve, reject) => {
									let objData = new FormData()
									objData.append('file', file)
									objData.append('type', 'vacant_jobs')

									axios.post(`/instances/${this.$auth.user().instance.id}}/upload-image`, objData)
									.then(({data}) => {
										resolve(data.file_path);
									})
								});
							},
						}
					}
				},
				error_message: '',
				errorsBe: {},
				loadingSubmit: false

			};
		},
		async mounted(){
			await this.getVacantJobAnnouncementData()
		},
		validations: {
			vacantJobAnnouncement: {
				title: { required },
				steps_to_do: { required },
				reward: { required }
			}
		},
		methods: {
			async getVacantJobAnnouncementData(){
				await axios.get(`/vacant-job-announce/${this.$route.params.id}/show`)
				.then(({data}) => {
					this.vacantJobAnnouncement = data.data
					this.vacantJobAnnouncement.title = data.data.title ? data.data.title : ''; 
					this.vacantJobAnnouncement.steps_to_do = data.data.steps_to_do ? data.data.steps_to_do : '';
					this.vacantJobAnnouncement.reward = data.data.reward ? data.data.reward : ( data.data.reward_value ? this.$t('job_announcement.reward_value_example', {reward: data.data.reward_value }) : '' );
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { 
					this.loaded = true,
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			saveAction(){
				var btnSubmit = document.getElementById('buttonUpdate');
				btnSubmit.disabled = true;
				this.loadingSubmit = true;

				this.error_message = '';
				this.$v.$touch()
				if(!this.$v.$invalid){
					let objData = {
						title: this.vacantJobAnnouncement.title,
						mission: this.vacantJobAnnouncement.mission,
						target: this.vacantJobAnnouncement.target,
						field_of_activity: this.vacantJobAnnouncement.field_of_activity,
						successes: this.vacantJobAnnouncement.successes,
						company_values: this.vacantJobAnnouncement.company_values,
						steps_to_do: this.vacantJobAnnouncement.steps_to_do,
						reward: this.vacantJobAnnouncement.reward
					}
					this.updateVacantJob(objData, btnSubmit)
				}else{
					this.error_message = 'check_error';
					btnSubmit.disabled = false;
					this.loadingSubmit = false;
				}
			},
			updateVacantJob(objData, btnSubmit){
				axios.patch(`/vacant-job-announce/${this.vacantJobAnnouncement.id}`, objData)
				.then(() => {
					this.$router.push({name: 'vacant-jobs-show', params: { slug: this.vacantJobAnnouncement.vacant_job.slug} });
				})
				.catch(error => {
					this.errorsBe = error.response.data.errors ? error.response.data.errors : {};
					this.error_message = error.response.status == 500 ? this.$t('error.500') : 'check_error';
					btnSubmit.disabled = false;
					this.loadingSubmit = false;
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	.custom-file-error{
		color: #FB5454;
		font-size: 12px;
		width: 100%;
		text-align: right;
		margin-top: 10px;
	}
</style>